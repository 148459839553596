/* global color schemes */
@mixin flex {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.light-theme {
  --backdrop: 189, 208, 219;
  --col-bg-focus: #ffffff;
  --col-bg-primary: rgb(213 239 254);
  --col-bg-secondary: rgb(var(--backdrop));
  --col-bg-tertiary: rgb(185, 226, 250);
  --col-fg-primary: hsl(0, 0%, 10%);
  --col-fg-secondary: #7e7e7ea6;
  --col-fg-alert: #e4004c;
  --col-fg-active: hsl(191, 100%, 25%);
  --col-fg-active-darker: hsl(191, 99%, 47%);
  --col-fg-active-lighter: hsl(191, 100%, 23%);
  --col-accent-0: hsl(34, 83%, 25%);
  --col-accent-1: hsl(40, 65%, 35%);
  --col-accent-2: hsl(69, 37%, 37%);
  --col-accent-3: hsl(213, 14%, 37%);
  --col-accent-4: hsl(247, 45%, 41%);
}

.night-theme {
  --backdrop: 58, 60, 71;
  --col-bg-focus: #657179;
  --col-bg-primary: rgb(68, 76, 81);
  --col-bg-secondary: rgb(var(--backdrop));
  --col-bg-tertiary: rgb(63, 70, 74);
  --col-fg-primary: hsl(0, 0%, 95%);
  --col-fg-secondary: hsl(205, 7%, 52%);
  --col-fg-alert: #e4004c;
  --col-fg-active: hsl(191, 65%, 65%);
  --col-fg-active-darker: #0074d9;
  --col-fg-active-lighter: hsl(191, 65%, 75%);
  --col-accent-0: hsl(34, 83%, 55%);
  --col-accent-1: hsl(40, 65%, 65%);
  --col-accent-2: hsl(69, 37%, 67%);
  --col-accent-3: hsl(213, 14%, 67%);
  --col-accent-4: hsl(247, 45%, 71%);
  input[type="date"] {
    color-scheme: dark;
  }
}

* {
  --backdrop-translucent: rgb(var(--backdrop), 0.8);
}

.full-width {
  width: 100%;
}

.flex-center-justify {
  @include flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.flex-center {
  @include flex;
  align-items: center;
  justify-content: center;
}

.flex-left {
  @include flex;
  justify-content: left;
}

.flex-right {
  @include flex;
  justify-content: right;
}

.flex-grow {
  @include flex;
  flex-grow: 1;
}

.flex-wrap {
  @include flex;
  flex-wrap: wrap;
}

.flex-column {
  @include flex;
  flex-direction: column;
}

.flex {
  @include flex;
}

.no-underline {
  text-decoration: none;
}

.margin-left {
  margin-left: var(--ml, 0.5rem);
}

.margin-right {
  margin-right: var(--mr, 0.5rem);
}

.margin-top {
  margin-top: var(--mt, 0.5rem);
}

.margin-bottom {
  margin-bottom: var(--mb, 0.5rem);
}

.margin-x {
  margin-left: var(--ml, 0.5rem);
  margin-right: var(--mr, 0.5rem);
}

.margin-y {
  margin-top: var(--mt, 0.5rem);
  margin-bottom: var(--mb, 0.5rem);
}

.gap {
  gap: var(--gap, 0.5rem);
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.empty-hidden:empty {
  display: none;
}

.break-words {
  white-space: break-spaces;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.full-width {
  width: 100%;
  flex-basis: 100%;
}

.scale {
  transform: scale(var(--scale, 0.5));
}
